import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { parse } from "postgres-array";
import API from "../utils/API";

function ProjectReports() {
  const [searchParams] = useSearchParams();
  const [reports, setReports] = useState();

  useEffect(() => {
    const project_id = searchParams.get("puuid");
    if (project_id) {
      API.get(`/portal/project/${project_id}`, { params: { page: 0, limit: 999, type: "project" } })
        .then((r) => {
          const groupedReports = r.data.data.reduce((acc, report) => {
            if (!acc[report.id]) {
              acc[report.id] = [];
            }
            acc[report.id].push(report);
            return acc;
          }, {});

          const result = Object.keys(groupedReports).map((id) => ({
            id,
            reports: groupedReports[id],
          }));
          setReports(result);
        })
        .catch((e) => console.log(e));
    }
  }, [searchParams]);

  return reports ? (
    <div className="page-container">
      <div className="page-header">
        <div className="page-header-container">Reports ({reports && reports.length})</div>
      </div>

      <table className="primary-table">
        <thead>
          <tr>
            <th>id</th>
            <th>Date</th>
            <th>Name</th>
            <th>Locations</th>
          </tr>
        </thead>
        <tbody>
          {reports
            .sort((a, b) => b.id.localeCompare(a.id)) // Sort by id in descending order
            .map((r) => (
              <tr key={r.id}>
                <td>{r.id}</td>
                <td>{format(parseISO(r.reports[0].date), "dd MMM Y")}</td>

                <td>
                  {r.reports
                    .sort((a, b) => a.savePrefix.localeCompare(b.savePrefix))
                    .map((r) => (
                      <div key={r.file_url}>
                        <a href={r.file_url}>{`${r.savePrefix}`}</a>
                      </div>
                    ))}
                </td>
                <td>
                  {r?.reports
                    .find((report) => report.savePrefix === "CON-COMP")
                    ?.pour_locations?.slice(1, -1) // Remove the surrounding curly braces
                    .split(",") // Split by commas
                    .map((location, index) => (
                      <div key={index}>{location.trim().replace(/^"|"$/g, "") !== "NULL" && location.trim().replace(/^"|"$/g, "")}</div> // Trim quotes
                    ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  ) : (
    "loading..."
  );
}

// [...new Set(cylinders && cylinders.filter((opt) => opt.days_requested > 6).map((c) => c.workorder_id))];

export default ProjectReports;
